import { render, staticRenderFns } from "./ConfigureRank.vue?vue&type=template&id=39ec8fdc&scoped=true&"
import script from "./ConfigureRank.vue?vue&type=script&lang=ts&"
export * from "./ConfigureRank.vue?vue&type=script&lang=ts&"
import style0 from "./ConfigureRank.vue?vue&type=style&index=0&id=39ec8fdc&rel=stylesheet%2Fscss&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "39ec8fdc",
  null
  
)

export default component.exports